import React from "react"
import Wrapper from './wrapper'
import styles from './footer.module.scss'

const Footer = () => (
    <footer className={styles.footer}>
        <Wrapper>
            <strong>&copy; Stay Pal 2020.</strong>
        </Wrapper>
    </footer>
)

export default Footer